/* eslint-disable import/no-unresolved */
<template>
  <section id="live-speakers">
    <div>
      <add-new
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        @refetch-data="refetchData"
        @close="isAddNewUserSidebarActive = false"
      />

      <edit
        :is-edit-speaker-sidebar-active.sync="isEditSpeakerSidebarActive"
        :data="rowData"
        @refetch-data="refetchData"
        @close="isEditSpeakerSidebarActive = false"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>Eventors</label>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="my-table"
            ref="refUserListTable"
            class="position-relative"
            :items="filter"
            :fields="tableColumns"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: Role -->
            <template #cell(role)="data">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteSpeakerEvent(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import userStoreModule from './userStoreModule'
import AddNew from './AddNew.vue'
import Edit from './Edit.vue'
import { mapActions } from 'vuex'

export default {

  components: {
    AddNew,
    Edit,

    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditSpeakerSidebarActive = ref(false)

    const {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewSidebarActive,
      isEditSpeakerSidebarActive,

      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalSpeakerEvent: 0,
      list: [
        {
          id: 1,
          user_name: 'Thiago P. Andrade',
          user_cpf: '123.681.374-09',
          user_email: 'thiaguinho@exalta.com.br',
          title: 'Essé é um teste de trabalho cientifico',
          summary: null,
          description: 'Essé é um tabalho',
          path_pdf: 'trabalho.pdf',
          institution: 'Universidade dos testes',
          approved: true
        },
      ],
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      filter: [],
      searchValue: null,
      rowData: {
        id: null,
        user_name: null,
        user_cpf: null,
        user_email: null,
        title: null,
        summary: null,
        description: null,
        path_pdf: null,
        institution: null,
        approved: null,
      },
      tableColumns: [
        {
          key: 'user_name',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'user_cpf',
          label: 'CPF',
          sortable: true,
        },
        {
          key: 'user_email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Titulo',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.filter.length
    },
    speakerEvent() {
      return this.$store.state.congressAdministrator.speaker_event
    },
    eventsState() {
      return this.$store.state.congressAdministrator.events
    },
  },
  watch: {
  },
  mounted() {
    this.filter = this.list
  },
  created() {
    this.ActionListAllSpeakerEvent()
  },
  methods: {
    ...mapActions('congressAdministrator', [
      'ActionListAllSpeakerEvent',
      'ActionDeleteSpeakerToEvent'
    ]),
    edit(data) {
      this.rowData.id = data.id
      this.rowData.speaker_id = data.speaker_id
      this.rowData.event_id = data.event_id
      this.rowData.password_vmix = data.password_vmix
      this.isEditSpeakerSidebarActive = true
      console.log(this.rowData)
    },
    deleteSpeakerEvent(data) {
      this.ActionDeleteSpeakerToEvent({
        id: data.id
      })
    }
  },
}
</script>

<style lang="scss">
</style>
